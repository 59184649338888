import React from 'react'

const Loader = () => {
    return (
        <div style={
            {
                background: '#181D28',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                zIndex: 999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <svg width="75" height="100" viewBox="0 0 75 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_207_4171)">
                    <path
                    d="M0 11.9421V35.8314H12.154V23.8842H24.0863V11.9421H0ZM50.7878 11.9421V62.5246C50.7878 67.1441 49.6389 70.7449 47.3915 73.3472C45.1441 75.9494 41.7579 77.2404 37.3136 77.2404C32.8692 77.2404 29.5485 75.9645 27.3666 73.4077C25.1848 70.8508 24.0863 67.2147 24.0863 62.5246V35.8314H12.154V59.7156H0V62.5246C0 70.5936 1.58226 77.4371 4.74673 83.0552C7.7567 88.4888 12.3185 92.8998 17.848 95.7235C23.4111 98.5745 29.7366 99.9984 36.8248 99.995C43.5143 100.084 50.1337 98.6223 56.1644 95.7235C61.9084 92.9304 66.7009 88.5024 69.9409 82.9947C73.3002 77.3396 74.9899 70.5163 75.0101 62.5246V11.9421H50.7878Z"
                    fill="#F9F9EF" />
                    <path d="M12.154 11.9421H0V23.8842H12.154V11.9421Z" fill="#1A40C9" />
                    <path d="M24.0866 11.9421H12.1543V23.8842H24.0866V11.9421Z" fill="#2251FC" />
                    <path d="M24.0866 35.8313H12.1543V47.7734H24.0866V35.8313Z" fill="#2251FC" />
                    <path d="M36.0182 0H24.0859V11.9421H36.0182V0Z" fill="#5F14FE" />
                    <path d="M36.0182 23.8893H24.0859V35.8314H36.0182V23.8893Z" fill="#1278E6" />
                    <path d="M47.9508 35.8313H36.0186V47.7734H47.9508V35.8313Z" fill="#14C2FE" />
                    <g clip-path="url(#clip1_207_4171)">
                    <path
                        d="M0 11.9421V35.8314H12.154V23.8842H24.0863V11.9421H0ZM50.7878 11.9421V62.5246C50.7878 67.1441 49.6389 70.7449 47.3915 73.3472C45.1441 75.9494 41.7579 77.2404 37.3136 77.2404C32.8692 77.2404 29.5485 75.9645 27.3666 73.4077C25.1848 70.8508 24.0863 67.2147 24.0863 62.5246V35.8314H12.154V59.7156H0V62.5246C0 70.5936 1.58226 77.4371 4.74673 83.0552C7.7567 88.4888 12.3185 92.8998 17.848 95.7235C23.4111 98.5745 29.7366 99.9984 36.8248 99.995C43.5143 100.084 50.1337 98.6223 56.1644 95.7235C61.9084 92.9304 66.7009 88.5024 69.9409 82.9947C73.3002 77.3396 74.9899 70.5163 75.0101 62.5246V11.9421H50.7878Z"
                        fill="#F9F9EF" />
                    <path d="M12.154 11.9421H0V23.8842H12.154V11.9421Z" fill="#1D12E6" />
                    <path d="M24.0866 11.9421H12.1543V23.8842H24.0866V11.9421Z" fill="#2251FC" />
                    <path d="M24.0866 35.8313H12.1543V47.7734H24.0866V35.8313Z" fill="#2251FC" />
                    <path d="M36.0182 0H24.0859V11.9421H36.0182V0Z" fill="#5F14FE" />
                    <path d="M36.0182 23.8893H24.0859V35.8314H36.0182V23.8893Z" fill="#1278E6" />
                    <path d="M47.9508 35.8313H36.0186V47.7734H47.9508V35.8313Z" fill="#14C2FE" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_207_4171">
                    <rect width="75" height="100" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_207_4171">
                    <rect width="75" height="100" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
} 

export default Loader