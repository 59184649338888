import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TWrapperComponent } from '../../../types'

const Headline = ({children, className}: TWrapperComponent) => {
    return (
        <p className={twMerge('text-md font-extrabold', className)}>{children}</p>
    )
} 

export default Headline