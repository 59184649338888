import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollHandler = ({children}: {children: React.ReactNode}) => {
    const location = useLocation()
    useEffect(() => {
        if (location.hash) {
            const section = document.getElementById(location.hash.substring(1))

            if (section) {
                section.scrollIntoView({
                    behavior: 'smooth', 
                    block: 'start', 
                    inline: 'nearest'
                })
            }
        }
        else {
            window.scrollTo(0, 0)
        }
        
    }, [location])
    return (<>{children}</>)
} 

export default ScrollHandler