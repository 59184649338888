import React from 'react'
import clsx from 'clsx'
import style from './Skeleton.module.sass'

type SkeletonTextProps = {
    width?: string
}

const SkeletonText = ({width}: SkeletonTextProps) => {
    return (
        <span 
            style={width? {width: width} : {}}
            className={clsx(`
                inline-block
                bg-grey
                w-[200px] 
                rounded-lg 
                bg-opacity-10
                relative
                before:absolute
                before:w-[20px]
                before:h-full`, style.skeleton)}>
                &nbsp;
        </span>
    )
} 

export default SkeletonText