import React from 'react'
import Container from '../Container'
import telegramImg from './assets/telegram.svg'
import twitterImg from './assets/twitter.svg'
import linkedinImg from './assets/linkedin.svg'
import ytImg from './assets/yt.svg'
import githubImg from './assets/github.svg'
import mediumImg from './assets/medium.svg'
import redditImg from './assets/reddit.svg'
import Headline from './components/Headline'
import { Link } from 'react-router-dom'
import SocialLink from './components/SocialLink'
import ContainerXL from '../ContainerXL'
import ContactBtn from './components/ContactBtn'

const socials = [
    {img: telegramImg, url: 'https://t.me/UBIX_Network', alt: 'telegram'},
    {img: twitterImg, url: 'https://twitter.com/UBIX_Network', alt: 'twitter'},
    {img: linkedinImg, url: 'https://linkedin.com/company/ubixnetwork/', alt: 'linkedin'},
    {img: ytImg, url: 'https://www.youtube.com/@ubix_network', alt: 'youtube'},
    {img: githubImg, url: 'https://github.com/SilentNotaryEcosystem/Cil-core/', alt: 'github'},
    {img: mediumImg, url: 'https://ubix-network.medium.com/', alt: 'medium'},
    {img: redditImg, url: 'https://www.reddit.com/r/UBIXNetwork/', alt: 'reddit'}
]

const Footer = () => {
    return (
        <>
            <div 
                className='
                    bg-blue2
                    pt-[90px] 
                    pb-[65px]
                    xl:pt-[152px]
                    xl:pb-[115px]'>
                <ContainerXL className='grid grid-cols-3 xl:flex text-white xl:flex-row xl:justify-between'>
                    <div className='col-span-3 max-w-[360px] mb-12 xl:mb-0'>
                        <Headline className='mb-5'>Get in touch with us</Headline>
                        <p className='text-md leading-5 mb-9 xl:mb-14'>The best way to reach us is through our telegram community, but you may also contact us via email: <a href="mailto:pr@ubix.network" className='font-extrabold'>pr@ubix.network</a></p>
                        <ContactBtn/>
                    </div>

                    <div className='mb-12 col-span-3 md:col-span-1 xl:mb-0'>
                        <Headline className='mb-6'>Navigation</Headline>
                        <Link to="/" className='block mb-4'>Home</Link>
                        <Link to={{pathname: '/', hash: 'ecosystem'}} className='block mb-4'>Ecosystem</Link>
                        <Link to={{pathname: '/', hash: 'projects'}} className='block mb-4'>Projects</Link>
                        <Link to={{pathname: '/', hash: 'about'}} className='block mb-4'>About</Link>
                    </div>

                    <div className='mb-12 col-span-3 md:col-span-1 xl:mb-0'>
                        <Headline className='mb-6'>Documentation</Headline>
                        <a 
                            href='https://ubix.network/pdf/UBIX.NetworkWhitepaper.pdf' 
                            target='_blank' 
                            className='block mb-4'>
                                Whitepaper
                        </a>
                        <a 
                            href="https://ubix.wiki/#Introduction"
                            target='_blank' 
                            className='block mb-4'>
                            UBIX.Wiki
                        </a>
                        {/* <a 
                            href="https://helper.idledgers.com/IDL/IDLPrivacyPolicy.pdf"
                            target='_blank' 
                            className='block mb-4'>
                            Privacy Policy
                        </a> */}
                        <Link to="/faq" className='block mb-4'>FAQ</Link>
                        <Link to="/blog" className='block mb-4'>Blog</Link>
                    </div>

                    <div className='col-span-3 md:col-span-1 mb-[58px] xl:mb-0'>
                        <Headline className='mb-6'>Headquarters</Headline>
                        <p>Sigany LTD #1 Mapp<br/>Street Belize City,<br/>Belize</p>
                    </div>

                    <div className='col-span-3'>
                        <Headline className='mb-6'>We are Social</Headline>
                        <div className='flex flex-wrap max-w-[224px]'>
                            {socials.map((item) => 
                                <SocialLink 
                                    key={item.alt} 
                                    img={item.img} 
                                    url={item.url} 
                                    alt={item.alt}/>)}
                        </div>
                    </div>
                </ContainerXL>
            </div>

            <div className='bg-black'>
                <Container className='text-center py-5 xl:py-[18px]'>
                    <p className='text-white text-sm xl:text-md'>© 2021 - {(new Date().getFullYear())} UBIX - A Digital Universe. All Rights Reserved.</p>
                </Container>
            </div>
        </>
    )
} 

export default Footer