import React from 'react'
import { TBtn } from '../../types'
import {ReactComponent as ChevronSvg} from '../../assets/icons/chevron_right_small.svg'
import { animated, useSpring } from '@react-spring/web'
import { twMerge } from 'tailwind-merge'

const FillingBtn = ({children, click, className}: TBtn) => {
    const [springs, api] = useSpring(() => ({
        from: {
            width: '0%',
            backgroundColor: '#14C2FE'
        },
        config: { 
            mass: 1, 
            tension: 115.2, 
            friction: 24
        }
    }), [])
    const mouseEnterHandle = () => {
        api.start({
            to: {
                width: '100%',
                backgroundColor: '#1278E6'
            }
        })
    }
    const mouseLeaveHandle = () => {
        api.start({
            to: {
                width: '0%',
                backgroundColor: '#14C2FE'
            }
        })
    }
    return (
        <button 
            onClick={click}
            onMouseEnter={mouseEnterHandle}
            onMouseLeave={mouseLeaveHandle}
            className={twMerge(`
                group
                relative
                min-w-[304px]
                w-full
                h-[70px] 
                rounded-full
                text-white 
                border-1
                pl-8
                py-[9px]
                pr-2
                flex
                items-center
                font-manrope
                font-extrabold
                overflow-hidden`,
                className)}
            >
            <animated.div 
                style={springs}
                className='
                    absolute
                    left-0
                    top-0
                    h-full'/>
            <div className='relative'>
                {children}
            </div>
            <div 
                className='
                    relative
                    h-[52px] 
                    w-[52px] 
                    min-w-[52px]
                    rounded-full 
                    bg-white 
                    ml-auto 
                    flex 
                    justify-center 
                    items-center'>
                <ChevronSvg className='text-blue2'/>
            </div>
        </button>
    )
} 

export default FillingBtn