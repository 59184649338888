import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TWrapperComponent } from '../../types'

const H4 = ({children, className}: TWrapperComponent) => {
    return (
        <h4 className={twMerge('font-manrope font-extrabold text-lg text-white leading-4 py-[7px] xl:text-2xl xl:leading-[33px] xl:py-[9px]', className)}>
            {children}
        </h4>
    )
} 

export default H4