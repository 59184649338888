import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TWrapperComponent } from '../types'

const ContainerXL = React.forwardRef((
    {children, className}: TWrapperComponent, 
    ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
        <div ref={ref} className={twMerge('px-6 w-full max-w-full xl:w-[1378px] xl:mx-auto', className)}>
            {children}
        </div>
    )
})

export default ContainerXL