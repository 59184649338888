import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TBtn } from '../../types'


const BtnOutline = ({children, disabled, click, className}: TBtn) => {
    return (
        <button 
            disabled={disabled}
            onClick={click}
            className={twMerge(`
                w-full
                max-w-full
                rounded-lg
                border-1
                bg-none
                text-white
                hover:text-blue4
                hover:border-blue4
                active:text-blue3
                active:border-blue3
                text-md
                leading-6
                font-manrope
                font-extrabold
                p-[10px]
                md:w-[254px]`,
                className)}>
            {children}
        </button>
    )
} 

export default BtnOutline