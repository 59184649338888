import React, { useState } from 'react'
import { animated, useTransition } from '@react-spring/web'
import BtnOutline from '../../Buttons/BtnOutline'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useScrollTop } from '../../../utility/scrollTop.hook'

import {ReactComponent as BurgerSvg} from '../assets/burger.svg'
import {ReactComponent as ArrowSvg} from '../../../assets/icons/chevron_right_small.svg'
import {ReactComponent as CloseSvg} from '../assets/close.svg'
import logo from '../assets/logo.svg'


type MobileHeaderProps = {
    show: boolean
    nav: ({
        text: string;
        url: string;
        secondLevel?: undefined;
        } | {
            secondLevel: {
                text: string;
                url: string | {pathname: string, hash: string};
            }[];
            text?: undefined;
            url?: undefined;
        })[],
}

const MobileHeader = ({show, nav}: MobileHeaderProps) => {
    const scrollTop = useScrollTop()
    const [menuOpen, setMenuOpen] = useState(false)
    const transition = useTransition(menuOpen, {
        from: {
            left: '-100%',
            opacity: 0
        },
        enter: {
            left: '0%',
            opacity: 1
        },
        leave: {
            left: '-100%',
            opacity: 0
        }
    })
    const location = useLocation()
    React.useEffect(() => {
        setMenuOpen(false)
    }, [location])
    return (
        <header 
            className={
                twMerge(`
                    flex
                    py-[10px] 
                    px-[14px]
                    lg:hidden
                    fixed
                    top-0
                    w-full 
                    z-10
                    transition-all`, 
                    show || menuOpen ? 'top-0' : '-top-full',
                    scrollTop > 100 && !menuOpen ? 'backdrop-blur-[10px] bg-black bg-opacity-20' : '')}>
            <BurgerSvg onClick={() => setMenuOpen(true)}/>
            {transition((style, isOpen) => (
                isOpen && 
                    <>
                        <animated.div 
                            style={{left: style.left}} 
                            className='
                                fixed
                                flex
                                flex-col
                                top-0
                                w-[243px]
                                h-full
                                pt-[100px]
                                px-5
                                pb-[30px]
                                bg-black2
                                shadow-menu'>
                            <CloseSvg 
                                className='absolute top-[10px] left-[14px]' 
                                onClick={() => setMenuOpen(false)}/>
                            <Link to='/'>
                                <img 
                                    src={logo}
                                    alt="UBIX Network" 
                                    className='w-[131px] mx-auto'/>
                            </Link>
                            <div className='flex flex-col mt-[57px]'>
                                {nav.map((item) => (
                                    item.secondLevel ? 
                                        item.secondLevel.map((item) => (
                                            <Link 
                                                key={item.text}
                                                className='
                                                    text-grey 
                                                    pl-[23px] 
                                                    mb-4 
                                                    last:mb-6
                                                    leading-3 
                                                    font-manrope 
                                                    font-extrabold' 
                                                to={item.url}>
                                                    {item.text}
                                                </Link>
                                        ))
                                    : <Link 
                                        key={item.text}
                                        className='
                                            flex
                                            items-center
                                            text-white 
                                            mb-6 
                                            leading-3 
                                            font-manrope 
                                            font-extrabold' 
                                        to={item.url} >
                                            {item.text}
                                            <ArrowSvg/>
                                        </Link>
                                ))}
                            </div>
                            <a className='mt-auto' href="https://ubikiri.com/auth/login-registration?returnUrl=%2F" target='_blank'>
                                <BtnOutline className='p-[5px]' click={() => {}}>Log in</BtnOutline>
                            </a>
                        </animated.div>
                        <animated.div 
                            onClick={() => setMenuOpen(false)}
                            style={{opacity: style.opacity}}
                            className='
                                fixed 
                                top-0 
                                left-0 
                                w-screen 
                                h-screen 
                                backdrop-blur-[2px] 
                                bg-black2 
                                bg-opacity-30
                                -z-10'/>
                    </>))}
            </header>
    )
} 

export default MobileHeader