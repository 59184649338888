import React from 'react'

type SocialLinkProps = {
    img: string
    url: string
    alt: string
}

const SocialLink = ({img, url, alt}: SocialLinkProps) => {
    return (
        <a 
            href={url}
            target='_blank'
            className='flex justify-center items-center mr-2 mb-2 rounded-[4px] bg-white h-12 w-12'>
            <img src={img} alt={alt} className='h-min'/>
        </a>
    )
} 

export default SocialLink