import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TBtn } from '../../types'

const BtnFill = ({children, click, className}: TBtn) => {
    return (
        <button 
            onClick={click}
            className={twMerge(`
                w-full
                max-w-full
                rounded-lg
                bg-blue2
                hover:bg-blue3
                active:bg-blue1
                text-white
                text-md
                leading-6
                font-manrope
                font-extrabold
                p-[10px]
                md:w-[254px]`,
                className)}>
            {children}
        </button>
    )
} 

export default BtnFill