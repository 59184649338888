import React, { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Loader from './components/Loader'
import Notifications from './components/Notifications/Notifications'
import Redirect from './components/Redirect'
import ScrollHandler from './components/ScrollHandler'
import BlogPostSkeleton from './pages/Blog/components/skeletons/BlogPostSkeleton'
import BlogSkeleton from './pages/Blog/components/skeletons/BlogSkeleton'
import NotFound from './pages/NotFound/NotFound'

const Landing = lazy(() => import('./pages/Landing/Landing'))
const UBX = lazy(() => import('./pages/UBX/UBX'))
const Staking = lazy(() => import('./pages/Staking/Staking'))
const FAQ = lazy(() => import('./pages/FAQ/FAQ'))
const Guides = lazy(() => import('./pages/Guides/Guides'))
const Blog = lazy(() => import('./pages/Blog/Blog'))
const BlogPost = lazy(() => import('./pages/Blog/BlogPost'))

const BlogPostTopDagPlatforms = lazy(() => import('./pages/Blog/BlogPostTopDagPlatforms'))
const BlogPostUBXUseCases = lazy(() => import('./pages/Blog/BlogPostUBXUseCases'))
const BlogPostUbixDag = lazy(() => import('./pages/Blog/BlogPostUbixDag'))
const BlogPostUbixWorldwideRegulation = lazy(() => import('./pages/Blog/BlogPostUbixWorldwideRegulation'))
const BlogPostBearMarketUbix = lazy(() => import('./pages/Blog/BlogPostBearMarketUbix'))
const BlogPostUBXvsBTC = lazy(() => import('./pages/Blog/BlogPostUBXvsBTC'))
const BlogPostUBXvsETH = lazy(() => import('./pages/Blog/BlogPostUBXvsETH'))
const BlogPostWhyInvestInUbix = lazy(() => import('./pages/Blog/BlogPostWhyInvestInUbix'))
const BlogPostHowLiquidityInfluences = lazy(() => import('./pages/Blog/BlogPostHowLiquidityInfluences'))
const BlogPostUbix2023 = lazy(() => import('./pages/Blog/BlogPostUbix2023'))
const BlogPostUbixIntegratingChatGPT = lazy(() => import('./pages/Blog/BlogPostUbixIntegratingChatGPT'))
const BlogPostPassiveIncome = lazy(() => import('./pages/Blog/BlogPostPassiveIncome'))
const BlogPostHowToFindNextCryptoGem = lazy(() => import('./pages/Blog/BlogPostHowToFindNextCryptoGem'))
const BlogPostHowToGetPassiveIncome = lazy(() => import('./pages/Blog/BlogPostHowToGetPassiveIncome'))
const BlogPostHowToInvestInCrypto = lazy(() => import('./pages/Blog/BlogPostHowToInvestInCrypto'))
const BlogPostUBXvsIOTA = lazy(() => import('./pages/Blog/BlogPostUBXvsIOTA'))
const BlogPostWhoShouldNotInvest = lazy(() => import('./pages/Blog/BlogPostWhoShouldNotInvest'))
const BlogPostDiversification = lazy(() => import('./pages/Blog/BlogPostDiversification'))
const BlogPostHowToCreateAWallet = lazy(() => import('./pages/Blog/BlogPostHowToCreateAWallet'))
const BlogPostSilentNotaryOverview = lazy(() => import('./pages/Blog/BlogPostSilentNotaryOverview'))
const BlogPostExchangeFreeListings = lazy(() => import('./pages/Blog/BlogPostExchangeFreeListings'))
const BlogPostUbistakePassiveIncome = lazy(() => import('./pages/Blog/BlogPostUbistakePassiveIncome'))
const BlogPostGlobalTrends2030 = lazy(() => import('./pages/Blog/BlogPostGlobalTrends2030'))


function App() {
  
  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={NotFound}>
        <Header/>
        <Routes>
          <Route 
            path='/' 
            element={
              <Suspense fallback={<Loader/>}>
                <Landing/>
              </Suspense>}/>
          <Route 
            path='/ubx' 
            element={
              <Suspense fallback={<Loader/>}>
                <UBX/>
              </Suspense>}/>
          <Route 
            path='/staking' 
            element={
              <Suspense fallback={<Loader/>}>
                <Staking/>
              </Suspense>}/>
          <Route 
            path='/faq' 
            element={
              <Suspense fallback={<Loader/>}>
                <FAQ/>
              </Suspense>}/>
          <Route 
            path='/guides' 
            element={
              <Suspense fallback={<Loader/>}>
                <Guides/>
              </Suspense>}/>
          <Route 
            path='/blog' 
            element={
              <Suspense fallback={<BlogSkeleton/>}>
                <Blog/>
              </Suspense>}/>
          <Route 
            path='/blog/top-dag-platforms' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostTopDagPlatforms/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubx-use-cases' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUBXUseCases/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubix-dag' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUbixDag/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubix-worldwide-regulation' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUbixWorldwideRegulation/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/bear-market-and-ubix' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostBearMarketUbix/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubx-vs-btc' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUBXvsBTC/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubx-vs-eth' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUBXvsETH/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/why-invest-in-ubix' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostWhyInvestInUbix/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/how-liquidity-influences-your-investments' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostHowLiquidityInfluences/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubix-2023' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUbix2023/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubix-is-integrating-chatgpt' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUbixIntegratingChatGPT/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/passive-income-with-crypto' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostPassiveIncome/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/how-to-find-next-crypto-gem' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostHowToFindNextCryptoGem/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/how-to-get-passive-income-in-crypto-world' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostHowToGetPassiveIncome/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/how-to-invest-in-crypto' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostHowToInvestInCrypto/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubx-vs-iota' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUBXvsIOTA/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/who-should-not-invest-in-crypto' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostWhoShouldNotInvest/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/diversification' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostDiversification/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/how-to-create-an-ubikiri-wallet' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostHowToCreateAWallet/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/silent-notary-overview-and-the-way-forward' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostSilentNotaryOverview/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubix-exchange-free-listings' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostExchangeFreeListings/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/ubistake-passive-income' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostUbistakePassiveIncome/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/global-trends-2030' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                <ScrollHandler>
                  <BlogPostGlobalTrends2030/>
                </ScrollHandler>
              </Suspense>}/>
          <Route 
            path='/blog/:id' 
            element={
              <Suspense fallback={<BlogPostSkeleton/>}>
                  <BlogPost/>
              </Suspense>}/>
          
          <Route path='/uips' element={<Redirect to='https://uip.ubix.network/open'/>}/>
          <Route path='*' element={<Navigate to='/'/>}/>
        </Routes>

        <Footer/>
        <Notifications/>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
