import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import FillingBtn from '../../components/Buttons/FillingBtn'
import Container from '../../components/Container'
import H3 from '../../components/Typography/H3'
import GradientsCanvas from '../Landing/sections/Hero/components/Gradients/GradientsCanvas'
import RectanglesBack from './components/RectanglesBack'
import RectanglesTop from './components/RectanglesTop'
import style from './NotFound.module.scss'

const NotFound = ({error, resetErrorBoundary}: {error: any, resetErrorBoundary: any}) => {
    return (
        <div className='w-full h-screen flex flex-col items-center overflow-clip'>
            <div className='absolute top-0 left-0 flex justify-center w-full h-full z-[-1] overflow-hidden'>
                <GradientsCanvas 
                    animated blur 
                    className='top-[40px] xl:top-[150px] max-h-[600px] md:max-h-[800px] blur-[65px] md:blur-[85px]'/>
            </div>
            <div className='
                mt-0
                md:mt-[151px]
                justify-self-start
                scale-[.64] 
                xl:scale-100 
                w-[928px] 
                h-[492px] 
                min-w-[928px] 
                min-h-[492px] 
                flex 
                flex-col 
                justify-center 
                relative'>
                <RectanglesBack/>
                <H3 className='
                    text-3xl 
                    leading-7
                    text-center 
                    p-0 
                    xl:p-0 
                    mb-[6px] 
                    xl:text-3xl 
                    xl:leading-7
                    xl:mb-[10px]'>
                        Error
                </H3>
                <h1 className={clsx(`
                    text-center
                    text-white 
                    font-extrabold 
                    font-manrope 
                    text-[272px]
                    leading-[220px]
                    xl:text-[272px]
                    xl:leading-[220px]
                    `, style.notFound__text)}>
                        404
                </h1>
                <RectanglesTop/>
            </div>

            <div className='relative flex h-[100px] items-center xl:mt-[60px]'>
                <p className='text-white text-center text-md xl:text-xl mb-5 px-6'>We couldn't find the page you're looking for...</p>
            </div>

            <Container className='mb-16 mt-auto xl:mt-0 w-full'>
                <Link to='/' className='block mx-auto md:max-w-[304px]'>
                    <FillingBtn click={resetErrorBoundary} className=''>Go to UBIX.Network</FillingBtn>
                </Link>
            </Container>
        </div>
    )
} 

export default NotFound