import React, { useEffect } from 'react'

type RedirectProps = {
    to: string
}

const Redirect = ({to}: RedirectProps) => {
    useEffect(() => {
        window.location.href = to
     }, [to])
    return (
        <div>Redirecting...</div>
    )
} 

export default Redirect