import React from 'react'
import { Link } from 'react-router-dom'
import BtnFill from '../../../../components/Buttons/BtnFill'
import Container from '../../../../components/Container'
import style from '../../../../components/Skeleton/Skeleton.module.sass'
import H3 from '../../../../components/Typography/H3'
import Meta from '../Meta'
import PostsSkeleton from './PostsSkeleton'
import TitleSkeleton from './TitleSkeleton'

const BlogSkeleton = () => {
    return (
        <>
            <Container className='grid md:grid-cols-2 gap-6 xl:gap-12 pt-[86px] pb-[106px] md:pt-[128px] xl:pt-[320px] xl:pb-[170px]'>
                <div className='rounded-2xl h-fit relative overflow-hidden'>
                    <div className={`bg-grey h-[180px] md:h-[400px] w-full ${style.skeleton}`}/>
                </div>
                <div>
                    <div className='mb-6 xl:mb-3'>
                        <Meta 
                            author={undefined} 
                            avatar={undefined} 
                            timestamp={undefined} 
                            readingTime={undefined}/>
                    </div>

                    <TitleSkeleton/>

                    <BtnFill click={() => {}} disabled={true}>Open Blog Post</BtnFill>
                </div>
            </Container>

            <Container className='pb-[146px] xl:pb-[234px]'>
                <H3 className='mb-6 xl:mb-8'>More Blog Posts</H3>
                <div className='grid gap-5 md:gap-4 md:grid-cols-2 xl:grid-cols-3'>
                    <PostsSkeleton/>
                </div>
            </Container>
        </>
    )
} 

export default BlogSkeleton