import React from 'react'
import SkeletonText from '../../../../components/Skeleton/SkeletonText'
import Meta from '../Meta'
import TitleSkeleton from './TitleSkeleton'
import style from '../../../../components/Skeleton/Skeleton.module.sass'
import PostsSkeleton from './PostsSkeleton'
import H3 from '../../../../components/Typography/H3'
import Container from '../../../../components/Container'

const BlogPostSkeleton = () => {
    return (
        <Container className='pt-[86px] pb-[128px] md:pt-[128px] xl:pt-[256px]'>
            <div>
                <div className='mb-6 xl:mb-10'>
                    <Meta 
                        author={undefined} 
                        avatar={undefined} 
                        timestamp={undefined} 
                        readingTime={undefined}/>
                </div>
                <TitleSkeleton/>
                <div className='
                    rounded-2xl 
                    relative 
                    overflow-hidden
                    mb-[86px]
                    xl:mb-[64px]'>
                    <div className={`bg-grey h-[180px] md:h-[400px] w-full ${style.skeleton}`}/>
                </div>

                <div className='blog-post mb-[128px]'>
                    <h4><SkeletonText width='300px'/></h4>
                    <br/><br/>
                    <div className='mb-2'><SkeletonText width='80%'/></div>
                    <div className='mb-2'><SkeletonText width='100%'/></div>
                    <div className='mb-2'><SkeletonText width='40%'/></div>
                    <div className='mb-2'><SkeletonText width='60%'/></div>
                    <div className='mb-2'><SkeletonText width='80%'/></div>
                </div>

                <H3 className='mb-6 xl:mb-8'>More Blog Posts</H3>
                
                <div className='grid gap-5 md:gap-4 md:grid-cols-2 xl:grid-cols-3'>
                    <PostsSkeleton/>
                </div>
            </div>
        </Container>
    )
} 

export default BlogPostSkeleton