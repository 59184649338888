import React from 'react'
import { twMerge } from 'tailwind-merge'
import { TWrapperComponent } from '../../types'

const Card = ({children, className}: TWrapperComponent) => {
    return (
        <div className={twMerge(`
            rounded-2xl
            bg-black2
            px-9
            py-7
            xl:py-8
            xl:px-8`, 
            className)}>
            {children}
        </div>
    )
} 

export default Card