import React from 'react'
import clsx from 'clsx'
import { ToastBar, Toaster, resolveValue  } from 'react-hot-toast'
import {ReactComponent as CheckImg} from './assets/check_round.svg'
import {ReactComponent as CrossImg} from './assets/cross_round.svg'

const Notifications = () => {
    return (
        <Toaster 
            containerStyle={{inset: '24px'}} >
                {(t) => (
                    <ToastBar style={{
                        display: 'block',
                        maxWidth: 'unset',
                        background: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        padding: 0
                    }} toast={t}>
                        {() => (
                            <div className={clsx(
                                'relative flex items-center w-full p-4 transition-all rounded-lg text-white md:w-auto',
                                {
                                    'bg-green': t.type === 'success',
                                    'bg-red': t.type === 'error',
                                })}>
                                {t.type === 'success' && <CheckImg className='mr-4'/>}
                                {t.type === 'error' && <CrossImg className='mr-4'/>}
                                {resolveValue(t.message, t)}
                            </div>
                        )}
                    </ToastBar>)}
        </Toaster>
    )
} 

export default Notifications