import React, { useState } from 'react'
import SkeletonText from '../../../components/Skeleton/SkeletonText'
import style from '../../../components/Skeleton/Skeleton.module.sass'

type MetaProps = {
    author?: string
    avatar?: string
    timestamp?: number
    readingTime?: number
}

const Meta = ({author, avatar, timestamp, readingTime}: MetaProps) => {
    const [avatarLoaded, setAvatarLoaded] = useState<boolean>(false)
    return (
        <div className='flex items-center'>
            <div className='relative h-12 w-12 rounded-full overflow-hidden mr-4'>
                {!avatarLoaded && <div className={`h-full w-full bg-grey ${style.skeleton}`}/>}
                <img 
                    style={{height: avatarLoaded ? 'auto' : 0}}
                    src={avatar}
                    alt={author}
                    onLoad={() => setAvatarLoaded(true)}/>
            </div>
            <div>
                <p className='text-white text-md mb-2 leading-1 xl:leading-1 xl:text-xl'>
                    {author ? author : <SkeletonText width='120px'/>}
                </p>
                <p className='text-grey text-md leading-1 xl:leading-1 opacity-50'>
                    <span className='mr-4'>
                        {timestamp 
                            ? new Intl.DateTimeFormat("en-US", { month: 'short', day: 'numeric' }).format(timestamp)
                            : <SkeletonText width='70px'/>}
                    </span>
                    <span>
                        {readingTime !== undefined 
                            ? `${readingTime} Min read` 
                            : <SkeletonText width='90px'/>}
                    </span>
                </p>
            </div>
        </div>
    )
} 

export default Meta