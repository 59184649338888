import React from 'react'
import { MouseParallax } from 'react-just-parallax'

import rect1 from '../assets/rect1.svg'
import rect2 from '../assets/rect2.svg'
import rect3 from '../assets/rect3.svg'
import rect4 from '../assets/rect4.svg'
import rect5 from '../assets/rect5.svg'
import rect6 from '../assets/rect6.svg'

const s1 = 0.005
const s2 = 0.015
const s3 = 0.007
const s4 = 0.01
const s5 = 0.012
const k = 1.2

const RectanglesBack = () => {
    return (
        <>
            <MouseParallax isAbsolutelyPositioned strength={s1*k}>
                <img className='top-[80px] left-[-13px] absolute' src={rect1} alt=''/>
            </MouseParallax>
            <MouseParallax isAbsolutelyPositioned strength={s2*k}>
                <img className='top-[168px] right-[335px] absolute' src={rect2} alt=''/>
            </MouseParallax>
            <MouseParallax isAbsolutelyPositioned strength={s3*k}>
                <img className='bottom-[18px] left-[209px] absolute' src={rect3} alt=''/>
            </MouseParallax>
            <MouseParallax isAbsolutelyPositioned strength={s4*k}>
                <img className='bottom-[20px] right-[290px] absolute' src={rect4} alt=''/>
            </MouseParallax>
            <MouseParallax isAbsolutelyPositioned strength={s5*k}>
                <img className='top-[26px] left-[302px] absolute' src={rect5} alt=''/>
            </MouseParallax>
            <MouseParallax isAbsolutelyPositioned strength={s3*k}>
                <img className='top-[-70px] right-[262px] absolute' src={rect6} alt=''/>
            </MouseParallax>
        </>
    )
} 

export default RectanglesBack