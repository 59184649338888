import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import BtnOutline from '../../Buttons/BtnOutline'
import telegramImg from '../assets/telegram.svg'

const ContactBtn = () => {
    const [springs, api] = useSpring(() => ({
        from: {
            width: '0%',
            backgroundColor: '#14C2FE'
        },
        config: { 
            mass: 1, 
            tension: 115.2, 
            friction: 24
        }
    }), [])
    const mouseEnterHandle = () => {
        api.start({
            to: {
                width: '100%',
                backgroundColor: '#1278E6'
            }
        })
    }
    const mouseLeaveHandle = () => {
        api.start({
            to: {
                width: '0%',
                backgroundColor: '#14C2FE'
            }
        })
    }
    return (
        <a 
            href='https://t.me/UBIX_Network' 
            target='_blank' 
            onMouseEnter={mouseEnterHandle} 
            onMouseLeave={mouseLeaveHandle}
            onClick={() => {
                // @ts-ignore: Unreachable code error
                window.twq('event', 'tw-o4ylg-oeff9', {
                    email_address: null // use this to pass a user’s email address
                })
            }}>
            <BtnOutline 
                click={() => {}} 
                className='
                    relative 
                    overflow-hidden 
                    p-[14px] 
                    hover:text-white 
                    hover:border-white 
                    active:text-white 
                    active:border-white'>
                <animated.div 
                    style={springs}
                    className='
                        absolute
                        left-0
                        top-0
                        h-full'/>
                <div 
                    className='
                        absolute 
                        flex 
                        justify-center 
                        items-center 
                        rounded-[4px] 
                        bg-white 
                        left-2
                        top-[7px]
                        h-[52px] 
                        w-[52px]'>
                    <img src={telegramImg} alt='telegram' className='h-min'/>
                </div>
                <span className='relative ml-[26px]'>Contact Us</span>
                
            </BtnOutline>
        </a>
        
    )
} 

export default ContactBtn