import React from 'react'
import { MouseParallax } from 'react-just-parallax'

import rect7 from '../assets/rect7.svg'

const RectanglesTop = () => {
    return (
        <MouseParallax isAbsolutelyPositioned strength={0.03}>
            <img className='top-[81px] right-[-21px] absolute' src={rect7} alt=''/>
        </MouseParallax>
    )
} 

export default RectanglesTop