import React from 'react'
import Card from '../../../../components/Cards/Card'
import style from '../../../../components/Skeleton/Skeleton.module.sass'
import SkeletonText from '../../../../components/Skeleton/SkeletonText'
import H4 from '../../../../components/Typography/H4'

const PostCardSkeleton = () => {
    return (
        <Card className='p-0 xl:p-0'>
                <div className={`
                    flex
                    justify-center
                    items-center
                    overflow-hidden
                    rounded-t-2xl
                    h-[166px] 
                    ${ style.skeleton}`}>
                </div>
                <div className='p-8 xl:p-8'>
                    <H4 className='p-0 mb-8 xl:p-0'><SkeletonText/></H4>

                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='65%'/></div>
                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='85%'/></div>
                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='75%'/></div>
                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='100%'/></div>
                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='45%'/></div>
                    <div className='text-grey text-sm leading-[14px] pb-2 xl:text-md xl:leading-1 xl:pb-2'><SkeletonText width='65%'/></div>

                    <p className='text-white'><b>read full blog post...</b></p>
                </div>
            </Card>
    )
} 

export default PostCardSkeleton