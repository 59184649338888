import React from 'react'
import PostCardSkeleton from './PostCardSkeleton'

const PostsSkeleton = () => {
    return (
        <>
            <PostCardSkeleton/>
            <PostCardSkeleton/>
            <PostCardSkeleton/>
        </>
    )
} 

export default PostsSkeleton