import React from 'react'
import SkeletonText from '../../../../components/Skeleton/SkeletonText'

const TitleSkeleton = () => {
    return (
        <div className='mb-6 xl:mb-8'>
            <div className='text-4xl leading-5 py-2 xl:text-5xl xl:leading-[40px] xl:py-[13px]'>
                <SkeletonText width='250px'/>
            </div>
            <div className='text-4xl leading-5 py-2 xl:text-5xl xl:leading-[40px] xl:py-[13px]'>
                <SkeletonText width='320px'/>
            </div>
            <div className='text-4xl leading-5 py-2 xl:text-5xl xl:leading-[40px] xl:py-[13px]'>
                <SkeletonText width='180px'/>
            </div>
        </div>
    )
} 

export default TitleSkeleton