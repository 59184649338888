import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

type GradientsCanvasProps = {
    animated?: boolean
    blur: boolean
    className?: string
}

const GradientsCanvas = ({animated, blur, className}: GradientsCanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        if (!canvasRef.current) return

        const canvas = canvasRef.current;
        const c = canvas.getContext('2d');
        const w = canvas.width
        const h = canvas.height

        if (!c) return

        const tick = (scale: number, scale1: number) => {
            c.clearRect(0, 0, w, h)

            // c.filter = "blur(150px)";
            // c.beginPath();
            // c.moveTo(0, 0);
            // c.lineTo(1875, 0);
            // c.lineTo(1875, 1200);
            // c.lineTo(0, 1200);
            // c.closePath();

            // c.fillStyle = "rgba(0, 0, 0, 0)";
            // c.fillStyle = "#FFFFFF";
            // c.fill("evenodd");
            
            // c.setTransform(1.33333, 0, 0, 1.33333, 0, 0);
            c.setTransform(1, 0, 0, 1, 390, 200);

            c.fillStyle = `rgba(28, 109, 192, ${.9*scale})`
            c.ellipse(864, 412, 864, 299, 0, 0, 360)
            c.fill()

            c.fillStyle = "#FFFFFF";
            let storedTransform = c.getTransform();
            // c.setTransform(scale, 0, 0, scale, -(w*scale/2 - w/2), -(h*scale/2 - h/2));
            c.beginPath();
            c.moveTo(0, 411.89999);
            c.bezierCurveTo(0, 246.54593, 386.82596, 112.5, 864, 112.5);
            c.bezierCurveTo(1341.17407, 112.5, 1728, 246.54593, 1728, 411.89999);
            c.bezierCurveTo(1728, 577.25403, 1341.17407, 711.29999, 864, 711.29999);
            c.bezierCurveTo(386.82596, 711.29999, 0, 577.25403, 0, 411.89999);
            c.setTransform(storedTransform);

            c.beginPath();
            c.moveTo(1167, 438.70001);
            c.bezierCurveTo(1167, 281.35406, 1312.05969, 153.80002, 1491, 153.80002);
            c.bezierCurveTo(1669.94031, 153.80002, 1815, 281.35406, 1815, 438.70001);
            c.bezierCurveTo(1815, 596.04596, 1669.94031, 723.59998, 1491, 723.59998);
            c.bezierCurveTo(1312.05969, 723.59998, 1167, 596.04596, 1167, 438.70001);
            c.fillStyle = "rgba(50, 5, 178, 0.9)";
            c.fill("nonzero");

            storedTransform = c.getTransform();
            
            // alert(`${w}, ${h}`)
            let d = 574
            c.setTransform(scale, 0, 0, scale, -(d*scale - d), -(h*scale/2 - h/2));
            // c.scale(scale, scale)
            c.beginPath();
            c.moveTo(284, 765.79999);
            c.bezierCurveTo(284, 626.45856, 412.49426, 513.5, 571, 513.5);
            c.bezierCurveTo(729.50574, 513.5, 858, 626.45856, 858, 765.79999);
            c.bezierCurveTo(858, 905.14142, 729.50574, 1018.09998, 571, 1018.09998);
            c.bezierCurveTo(412.49426, 1018.09998, 284, 905.14142, 284, 765.79999);
            c.fillStyle = "rgba(49, 33, 234, 0.9)";
            c.fill("nonzero");
            // c.scale(1/scale, 1/scale)
            c.setTransform(storedTransform);
            // c.restore()
            // c.setTransform(1.33333, 0, 0, 1.33333, 0, 0);
            // c.setTransform(1, 0, 0, 1, 390, 200);

            c.beginPath();
            c.moveTo(299, 29);
            c.lineTo(41, 29);
            c.lineTo(41, 249);
            c.lineTo(299, 249);
            c.lineTo(299, 29);
            c.closePath();
            c.moveTo(299, 470);
            c.lineTo(41, 470);
            c.lineTo(41, 690);
            c.lineTo(299, 690);
            c.lineTo(299, 470);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(556, 250);
            c.lineTo(299, 250);
            c.lineTo(299, 470);
            c.lineTo(556, 470);
            c.lineTo(556, 250);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(814, 470);
            c.lineTo(556, 470);
            c.lineTo(556, 690);
            c.lineTo(814, 690);
            c.lineTo(814, 470);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(583, 64);
            c.lineTo(325, 64);
            c.lineTo(325, 284);
            c.lineTo(583, 284);
            c.lineTo(583, 64);
            c.closePath();
            c.moveTo(583, 505);
            c.lineTo(325, 505);
            c.lineTo(325, 725);
            c.lineTo(583, 725);
            c.lineTo(583, 505);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(840, 285);
            c.lineTo(583, 285);
            c.lineTo(583, 505);
            c.lineTo(840, 505);
            c.lineTo(840, 285);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1098, 505);
            c.lineTo(840, 505);
            c.lineTo(840, 725);
            c.lineTo(1098, 725);
            c.lineTo(1098, 505);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1016, 139);
            c.lineTo(853, 139);
            c.lineTo(853, 279);
            c.lineTo(1016, 279);
            c.lineTo(1016, 139);
            c.closePath();
            c.moveTo(1016, 418);
            c.lineTo(853, 418);
            c.lineTo(853, 557);
            c.lineTo(1016, 557);
            c.lineTo(1016, 418);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1179, 0);
            c.lineTo(1016, 0);
            c.lineTo(1016, 139);
            c.lineTo(1179, 139);
            c.lineTo(1179, 0);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1179, 279);
            c.lineTo(1016, 279);
            c.lineTo(1016, 418);
            c.lineTo(1179, 418);
            c.lineTo(1179, 279);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1342, 418);
            c.lineTo(1179, 418);
            c.lineTo(1179, 557);
            c.lineTo(1342, 557);
            c.lineTo(1342, 418);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1039, 297);
            c.lineTo(781, 297);
            c.lineTo(781, 517);
            c.lineTo(1039, 517);
            c.lineTo(1039, 297);
            c.closePath();
            c.moveTo(1039, 737);
            c.lineTo(781, 737);
            c.lineTo(781, 957);
            c.lineTo(1039, 957);
            c.lineTo(1039, 737);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1296, 77);
            c.lineTo(1039, 77);
            c.lineTo(1039, 297);
            c.lineTo(1296, 297);
            c.lineTo(1296, 77);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1296, 517);
            c.lineTo(1039, 517);
            c.lineTo(1039, 737);
            c.lineTo(1296, 737);
            c.lineTo(1296, 517);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1554, 737);
            c.lineTo(1296, 737);
            c.lineTo(1296, 957);
            c.lineTo(1554, 957);
            c.lineTo(1554, 737);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(299, 29);
            c.lineTo(41, 29);
            c.lineTo(41, 249);
            c.lineTo(299, 249);
            c.lineTo(299, 29);
            c.closePath();
            c.moveTo(299, 470);
            c.lineTo(41, 470);
            c.lineTo(41, 690);
            c.lineTo(299, 690);
            c.lineTo(299, 470);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(556, 250);
            c.lineTo(299, 250);
            c.lineTo(299, 470);
            c.lineTo(556, 470);
            c.lineTo(556, 250);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(814, 470);
            c.lineTo(556, 470);
            c.lineTo(556, 690);
            c.lineTo(814, 690);
            c.lineTo(814, 470);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(583, 64);
            c.lineTo(325, 64);
            c.lineTo(325, 284);
            c.lineTo(583, 284);
            c.lineTo(583, 64);
            c.closePath();
            c.moveTo(583, 505);
            c.lineTo(325, 505);
            c.lineTo(325, 725);
            c.lineTo(583, 725);
            c.lineTo(583, 505);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(840, 285);
            c.lineTo(583, 285);
            c.lineTo(583, 505);
            c.lineTo(840, 505);
            c.lineTo(840, 285);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1098, 505);
            c.lineTo(840, 505);
            c.lineTo(840, 725);
            c.lineTo(1098, 725);
            c.lineTo(1098, 505);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1016, 139);
            c.lineTo(853, 139);
            c.lineTo(853, 279);
            c.lineTo(1016, 279);
            c.lineTo(1016, 139);
            c.closePath();
            c.moveTo(1016, 418);
            c.lineTo(853, 418);
            c.lineTo(853, 557);
            c.lineTo(1016, 557);
            c.lineTo(1016, 418);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1179, 0);
            c.lineTo(1016, 0);
            c.lineTo(1016, 139);
            c.lineTo(1179, 139);
            c.lineTo(1179, 0);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1179, 279);
            c.lineTo(1016, 279);
            c.lineTo(1016, 418);
            c.lineTo(1179, 418);
            c.lineTo(1179, 279);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1342, 418);
            c.lineTo(1179, 418);
            c.lineTo(1179, 557);
            c.lineTo(1342, 557);
            c.lineTo(1342, 418);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1039, 297);
            c.lineTo(781, 297);
            c.lineTo(781, 517);
            c.lineTo(1039, 517);
            c.lineTo(1039, 297);
            c.closePath();
            c.moveTo(1039, 737);
            c.lineTo(781, 737);
            c.lineTo(781, 957);
            c.lineTo(1039, 957);
            c.lineTo(1039, 737);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1296, 77);
            c.lineTo(1039, 77);
            c.lineTo(1039, 297);
            c.lineTo(1296, 297);
            c.lineTo(1296, 77);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1296, 517);
            c.lineTo(1039, 517);
            c.lineTo(1039, 737);
            c.lineTo(1296, 737);
            c.lineTo(1296, 517);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1554, 737);
            c.lineTo(1296, 737);
            c.lineTo(1296, 957);
            c.lineTo(1554, 957);
            c.lineTo(1554, 737);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(340, 29);
            c.lineTo(82, 29);
            c.lineTo(82, 249);
            c.lineTo(340, 249);
            c.lineTo(340, 29);
            c.closePath();
            c.moveTo(340, 470);
            c.lineTo(82, 470);
            c.lineTo(82, 690);
            c.lineTo(340, 690);
            c.lineTo(340, 470);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            
            d = 515
            let h2 = 440
            storedTransform = c.getTransform()
            c.setTransform(scale, 0, 0, scale, -(d*scale/2 - d), -(h2*scale/2 - h2))
            c.beginPath();
            c.moveTo(597, 250);
            c.lineTo(340, 250);
            c.lineTo(340, 470);
            c.lineTo(597, 470);
            c.lineTo(597, 250);
            c.closePath();
            c.fillStyle = "rgba(230, 18, 18, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(855, 470);
            c.lineTo(597, 470);
            c.lineTo(597, 690);
            c.lineTo(855, 690);
            c.lineTo(855, 470);
            c.closePath();
            c.setTransform(storedTransform)

            d = 258
            h2 = 220
            storedTransform = c.getTransform()
            c.scale(scale, scale)
            c.translate(-(d*scale - d), -(h2*scale - h2))
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(624, 64);
            c.lineTo(366, 64);
            c.lineTo(366, 284);
            c.lineTo(624, 284);
            c.lineTo(624, 64);
            c.closePath();
            c.scale(1/scale, 1/scale)
            c.translate((d*scale - d), (h2*scale - h2))
            // c.translate((d/2), (h2/2))

            c.fillStyle = "rgba(20, 194, 254, 0.9)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(624, 505);
            c.lineTo(366, 505);
            c.lineTo(366, 725);
            c.lineTo(624, 725);
            c.lineTo(624, 505);
            c.closePath();

            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(881, 285);
            c.lineTo(624, 285);
            c.lineTo(624, 505);
            c.lineTo(881, 505);
            c.lineTo(881, 285);
            c.closePath();
            c.fillStyle = "rgba(249, 249, 239, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1057, 139);
            c.lineTo(894, 139);
            c.lineTo(894, 279);
            c.lineTo(1057, 279);
            c.lineTo(1057, 139);
            c.closePath();
            c.moveTo(1057, 418);
            c.lineTo(894, 418);
            c.lineTo(894, 557);
            c.lineTo(1057, 557);
            c.lineTo(1057, 418);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1220, 0);
            c.lineTo(1057, 0);
            c.lineTo(1057, 139);
            c.lineTo(1220, 139);
            c.lineTo(1220, 0);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1220, 279);
            c.lineTo(1057, 279);
            c.lineTo(1057, 418);
            c.lineTo(1220, 418);
            c.lineTo(1220, 279);
            c.closePath();
            c.fillStyle = "rgba(18, 120, 230, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1383, 418);
            c.lineTo(1220, 418);
            c.lineTo(1220, 557);
            c.lineTo(1383, 557);
            c.lineTo(1383, 418);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            d = 773
            let h1 = 880
            storedTransform = c.getTransform();
            // c.setTransform(scale, 0, 0, scale, -(d*scale/2 - d/2), -(h1*scale/2 - h1/2));
            // c.setTransform(scale, 0, 0, scale, -(d*scale/2), -(h1*scale/2));
            c.scale(scale1, scale1)
            c.translate(-(d*scale1/2 - d/2), -(h*scale1/2 - h/2))
        
            c.beginPath();
            c.moveTo(1080, 297);
            c.lineTo(822, 297);
            c.lineTo(822, 517);
            c.lineTo(1080, 517);
            c.lineTo(1080, 297);
            c.closePath();
            c.moveTo(1080, 737);
            c.lineTo(822, 737);
            c.lineTo(822, 957);
            c.lineTo(1080, 957);
            c.lineTo(1080, 737);
            c.closePath();
            c.fillStyle = "rgba(34, 81, 252, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1337, 77);
            c.lineTo(1080, 77);
            c.lineTo(1080, 297);
            c.lineTo(1337, 297);
            c.lineTo(1337, 77);
            c.closePath();
            c.fillStyle = "rgba(95, 20, 254, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1337, 517);
            c.lineTo(1080, 517);
            c.lineTo(1080, 737);
            c.lineTo(1337, 737);
            c.lineTo(1337, 517);
            c.closePath();
            c.fillStyle = "rgba(14, 150, 44, 0.5)";
            c.fill("nonzero");
            c.beginPath();
            c.moveTo(1595, 737);
            c.lineTo(1337, 737);
            c.lineTo(1337, 957);
            c.lineTo(1595, 957);
            c.lineTo(1595, 737);
            c.closePath();
            c.fillStyle = "rgba(20, 194, 254, 0.5)";
            c.fill("nonzero");
            c.setTransform(storedTransform)

            c.beginPath();
            c.moveTo(-110, 336.70001);
            c.bezierCurveTo(-110, 221.16205, -3.44377, 127.50002, 128, 127.50002);
            c.bezierCurveTo(259.44379, 127.50002, 366, 221.16205, 366, 336.70001);
            c.bezierCurveTo(366, 452.23798, 259.44379, 545.90002, 128, 545.90002);
            c.bezierCurveTo(-3.44377, 545.90002, -110, 452.23798, -110, 336.70001);
            // c.fillStyle = "rgba(225, 247, 255, 0.05)";
            c.fillStyle = "rgba(83, 91, 94, 0.1)";
            c.fill("nonzero");
            c.setTransform(1, 0, 0, 1, 0, 0);
        }

        let start = 0;
        let scale = .7;
        let scale1 = .7
        const step = .002
        const k = .8
        let l = step, l1 = step
        const animate = (timestamp: number) => {
            const elapsed = timestamp - start;
            
            // if (elapsed > 10) {
                start = timestamp;
                tick(scale, scale1);
                if (scale >= 1.5) {
                    l = -step
                }
                if (scale < .7) {
                    l = step
                }

                if (scale1 >= 1.5) {
                    l1 = -step
                }
                if (scale1 < .7) {
                    l1 = step
                }
                
                scale = scale + l
                scale1 = scale1 + l1*.7
            // }
            requestAnimationFrame(animate); 
        }
        animate(start)
    },[]);
    return (
        <canvas 
            ref={canvasRef} 
            width="2500" 
            height="1600" 
            className={twMerge(clsx(`
                absolute 
                overflow-visible 
                mx-auto 
                left-auto 
                right-auto 
                top-[-461px] 
                xl:top-[-200px]`,
                blur && 'blur-[143px]'), className)}/>
    )
}

export default GradientsCanvas