import { useLayoutEffect, useState } from 'react'

export const useScrollTop = () => {
    const [scrollTop, setScrollTop] = useState<number>(0)

    useLayoutEffect(() => {
        function updateScrollTop() {
            setScrollTop(window.pageYOffset)
        }

        window.addEventListener('scroll', updateScrollTop)
        updateScrollTop()
        return () => window.removeEventListener('scroll', updateScrollTop)
    }, [scrollTop])

  return scrollTop
}